import { parseTextToHtml } from '../functions/dom'
import { jsonFetch, jsonFetchForm } from '../functions/http'

export const FormModifier = (BaseClass, type = 'form') =>
  class extends BaseClass {
    connectedCallback () {
      this.action = null
      if (typeof this.render === 'function') {
        this.render()
      }
      this.addEventListeners()

      if (type === 'query') {
        this.form.addEventListener('keypress', this.onKeyPress.bind(this))
      }
    }

    handleEvent (e) {
      e.preventDefault()
      this.disableButtons(e, true)
      this.submitForm(e)
    }

    submitForm (e) {
      // Data
      const inputValues = Array.from(this.getData()).map(input => {
        return {
          key: input.name !== '' ? input.name : input.dataset.name,
          value: input.value
        }
      })

      let data= null
      if (type === 'form') {
        data = new FormData()
        inputValues.map(input => data.append(input.key, input.value))
        // Flow
        this.includeFlow(data)
      } else {
        data = inputValues
          .map(input => {
            let key = input.key
            if (key.includes('form[')) {
              key = input.key.match(/(?<=\[)(.*?)(?=\])/g)
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              input.value
            )}`
          })
          .join('&')
      }
      // Fetch
      this.fetch(e, data)
    }

    includeFlow (formData) {
      const form = document.querySelector('form')
      if (form.getAttribute('is') === 'form-flow') {
        const step = form.querySelector('[name$=_step]')
        formData.append(step.name, step.value)
        const instance = form.querySelector('[name$=_instance]')
        formData.append(instance.name, instance.value)
      }
    }

    fetch (e, data) {
      let action = this.action ?? this.form.getAttribute('action') ?? 'create'
      const params = {
        method: this.form._method?.value ?? this.form.method
      }
      if (data instanceof FormData) {
        params.body = data
      } else {
        action = `${action.split('?')[0]}?${data}`
      }

      const handle = (e, response) => {
        this.clear()
        this.handleResponse(response)
        this.disableButtons(e, false)
      }
      if (type === 'form') {
        jsonFetchForm(action, params).catch(handle.bind(this, e))
      } else {
        this.action = action
        jsonFetch(action).then(handle.bind(this, e))
      }
    }

    load (bool) {
      const loader = document.querySelector('.loader')
      bool ? loader.classList.remove('hidden') : loader.classList.add('hidden')
    }

    clear () {
      const modifiedElement = document.getElementById(this.modified)
      const widget = modifiedElement.parentElement
      const errors = widget.querySelector('.form__error')
      errors?.remove()
    }

    handleResponse (response) {
      const html = parseTextToHtml(response)
      let ids = []
      if (typeof this.getIds === 'function') {
        ids = this.getIds()
      }

      ids.forEach(id => {
        document.getElementById(
          id
        ).parentElement.parentElement.innerHTML = html.getElementById(
          id
        ).parentElement.parentElement.innerHTML
      })
    }

    disableButtons (e, value) {
      e.target.disabled = value
    }

    onKeyPress (e) {
      if (e.code === 'Enter') {
        e.preventDefault()
      }
    }
  }
